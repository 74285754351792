import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import TopNav from "@components/topNav";
import { IoPlay } from "react-icons/io5";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";

const Header = ({ pageData }) => {
  // console.log(pageData);

  const theme = pageData.themeColor;
  const heroHeading = pageData.heroHeading || pageData.articleTitle;
  const themeTitleColor = pageData.themeTitleColor;
  const heroButtonVideo = pageData.heroButtonVideo;

  const heroText = pageData.heroText || pageData.articleShorttext;
  const heroImg = getImage(
    (pageData.heroImage && pageData.heroImage.file) ||
      (pageData.articleImage && pageData.articleImage.file)
  );
  const heroImgAlt =
    (pageData.heroImage && pageData.heroImage.alternativeText) ||
    (pageData.articleImage && pageData.articleImage.alternativeText);

  const heroButton1Link = pageData.heroButton1Link;
  const heroButton1Text = pageData.heroButton1Text;
  const heroButton2Link = pageData.heroButton2Link;
  const heroButton2Text = pageData.heroButton2Text;

  const [openVideo, setOpenVideo] = useState(false);

  const toggleVideo = () => {
    setOpenVideo(true);
  };

  const themeColor = () => {
    switch (theme) {
      case "sunset":
        return "#C12026";

      case "marine":
        return "#419B87";

      case "marineDark":
        return "#2B7363";

      case "fossil":
        return "#555555";

      case "orange":
        return "#FF8500";

      case "yellowAvert":
        return "#FBD500";

      case "lime":
        return "#A5CF07";

      case "purpleAvert":
        return "#D41AD0";

      case "midBlue":
        return "#17607D";

      case "lightBlue":
        return "#4FCDFF";

      case "boostPink":
        return "#BD7AB2";

      default:
        return "#C12026";
    }
  };

  return (
    <>
      <Transition.Root show={openVideo} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenVideo}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-middle bg-black rounded-md  overflow-hidden shadow-xl transform transition-all sm:my-2">
                <div className="md:block hidden">
                  <iframe
                    width="640"
                    height="360"
                    src={heroButtonVideo}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="visible sm:hidden">
                  <iframe
                    width="320"
                    height="240"
                    src={heroButtonVideo}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header>
        <div className={"relative bg-" + theme}>
          <div className="max-w-6xl mx-auto">
            <div
              className={
                "relative z-10 pb-8 bg-" +
                theme +
                " sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32"
              }
            >
              <svg
                className={
                  " hidden md:block pl-20 lg:block absolute right-0 inset-y-0 h-full w-56 text-" +
                  theme +
                  " transform translate-x-1/2"
                }
                fill="currentColor"
                viewBox="0 0 180 585"
                preserveAspectRatio="none"
                aria-hidden="true"
              >
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <polygon
                    id="Path"
                    fill={themeColor()}
                    fillRule="nonzero"
                    points="0.296038107 585 58.6111495 585 179.266137 296.235761 60.0246807 0 0.296038107 0"
                  ></polygon>
                </g>
              </svg>

              <TopNav />

              {/* <Video openVideo={openVideo} /> */}

              <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-left">
                  <h1
                    className={
                      themeTitleColor
                        ? "text-4xl tracking-loose font-bold text-" +
                          themeTitleColor +
                          " sm:text-5xl md:text-5xl font-heading"
                        : "text-4xl tracking-loose font-bold text-yellowAvert sm:text-5xl md:text-5xl font-heading"
                    }
                  >
                    {heroHeading}
                  </h1>

                  {/* Try padding conditional on text for DT / Mobile conditional */}

                  {/* standard */}
                  <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    {/* dt padding working ? */}
                    {/* <p className="pb-20 mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"> */}
                    {/* mobile padding working ? */}
                    {/* <p className="pb-48 mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"> */}
                    {heroText}
                  </p>

                  <div className="flex">
                    {/* Just button with link       */}
                    {heroButton1Link ? (
                      <div className="mt-4 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                        <div className="rounded-md p-2">
                          <Link
                            to={"/" + heroButton1Link}
                            className="w-full flex items-center justify-center px-8 py-3 text-base text-black font-medium rounded-md  bg-yellowAvert  md:py-4 md:text-lg md:px-10"
                          >
                            {heroButton1Text}
                          </Link>
                        </div>
                      </div>
                    ) : null}

                    {/* Just video       */}

                    {heroButtonVideo ? (
                      <div className="mt-4 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                        <div className="rounded-md p-2">
                          <Link
                            to="#"
                            onClick={() => toggleVideo()}
                            className="w-full flex items-center justify-center px-8 py-3 text-base text-black font-medium rounded-md  bg-white  md:py-4 md:text-lg md:px-10"
                          >
                            {heroButton2Text}

                            <IoPlay
                              className="inline-block w-7 h-7 pl-1"
                              aria-hidden="true"
                            />
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </main>
            </div>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <GatsbyImage
              image={heroImg}
              alt={heroImgAlt}
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            />
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: `Avert`,
};

export default Header;
